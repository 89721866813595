import React, { FC, useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import data from '../data/contact';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';

import { init } from 'emailjs-com';

const Contact: FC = () => {
  const [name, setName] = useState<string | undefined>('');
  const [phoneNumber, setPhoneNumber] = useState<number | undefined>();
  const [subject, setSubject] = useState<string | undefined>('');
  const [message, setMessage] = useState<string | undefined>('');
  const [canSubmit, setCanSubmit] = useState<boolean | undefined>(false);

  useEffect(() => {
    // This logic is supposed to disable the Submit button if any of the fields are empty
    if (name && phoneNumber && subject && message) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [name, phoneNumber, subject, message]);

  const { handleSubmit } = useForm();

  init('user_j6km9zJgzkzsoBVnx8AQK');

  // Simple function to clear all of the hooks after form is submitted
  const clearHooks = () => {
    setName('');
    setPhoneNumber(undefined);
    setMessage('');
    setSubject('');
    setCanSubmit(false);
  };
  // This function will normalize the phone number to (234) 123-3212
  const formatPhoneNumber = value => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6,
    )}-${phoneNumber.slice(6, 10)}`;
  };

  // This function will be called when the phoneNumber is changed
  const handlePhoneNumberInput = e => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
  };
  // This method will be called when the form is submitted
  const onSubmit = () => {
    emailjs.sendForm('gmail', 'template_a8kpbna', '#myForm').then(
      result => {
        console.log(result.text);
      },
      error => {
        console.log(error.text);
      },
    );
    clearHooks();
  };

  return (
    <Layout>
      <SEO title="Contact" />
      <div className="container box" style={{ backgroundColor: 'black' }}>
        <div className="columns" style={{ color: 'white' }}>
          <div className="column">
            <figure className="image is-2by3">
              <img src={data.image} alt="" />
            </figure>
          </div>
          <div className="column">
            <div className="has-text-centered py-5">
              <h1 className="title is-1" style={{ color: 'white' }}>
                {data.title}
              </h1>
              <p className="content is-size-5">{data.description}</p>
              <div className="container has-text-left">
                <h1 className="subtitle is-4" style={{ color: 'white' }}>
                  {data.address}
                </h1>
                <h1 className="subtitle is-4" style={{ color: 'white' }}>
                  {data.email}
                </h1>
                <h1 className="subtitle is-4" style={{ color: 'white' }}>
                  {data.phone}
                </h1>
              </div>
            </div>
            <div className="mb-2 mt-6">
              <h1 className="title is-4" style={{ color: 'white' }}>
                {data.form_title}
              </h1>
            </div>
            <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
              <div className="field is-grouped">
                <div className="control is-expanded">
                  <label className="label" style={{ color: 'white' }}>
                    Name
                  </label>
                  <input
                    className="input"
                    name="name"
                    type="text"
                    inputMode="text"
                    value={name}
                    placeholder="Name"
                    onChange={e => setName(e.target.value)}
                  />
                </div>

                <div className="control is-expanded">
                  <label className="label" style={{ color: 'white' }}>
                    Phone Number
                  </label>
                  <input
                    className="input"
                    type="tel"
                    placeholder="(123) 456-7890"
                    name="phoneNumber"
                    inputMode="numeric"
                    onChange={e => handlePhoneNumberInput(e)}
                    value={phoneNumber}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" style={{ color: 'white' }}>
                  Subject
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Subject"
                    inputMode="text"
                    name="subject"
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" style={{ color: 'white' }}>
                  Message
                </label>
                <div className="control">
                  <textarea
                    name="message"
                    className="textarea"
                    placeholder="Description"
                    inputMode="text"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                  />
                </div>
              </div>

              <div className="control">
                <button className="button is-danger" disabled={!canSubmit}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
