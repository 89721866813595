import data from './footer';
export default {
  title: 'Call Us or Send Us an Email',
  form_title: 'Completing this form will automatically send us an email',
  description:
    'Please reach out to us if you have any questions regarding any of our services. Also, appointments can be made by calling the phone number below. Thank you and we hope to see you soon🤍',
  address: `${data.address[1]}, ${data.address[3]}`,
  email: data.contact.email,
  phone: data.contact.phone,
  image: '../../contact.jpg',
};
